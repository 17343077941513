import { render, staticRenderFns } from "./allMainExpenditures.vue?vue&type=template&id=51da3179&scoped=true&"
import script from "./allMainExpenditures.vue?vue&type=script&lang=js&"
export * from "./allMainExpenditures.vue?vue&type=script&lang=js&"
import style0 from "./allMainExpenditures.vue?vue&type=style&index=0&id=51da3179&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51da3179",
  null
  
)

export default component.exports