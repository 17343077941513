<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small color="success" dark v-bind="attrs" v-on="on">
          {{ $t('MENU.ENTERPRICE.EXPENDITURE.MAIN_ASSETS') }} +
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Asosiy xarajat qo'shish</span>
        </v-card-title>
        <v-card-text>
          <div class="d-flex">
            <div class="col-6 pl-0">
              <v-text-field
                label="Nomi"
                v-model="nameInput"
                outlined
                dense
              ></v-text-field>
            </div>

            <div class="col-6 pr-0">
              <v-text-field
                label="Kod"
                v-model="codeInput"
                maxLength="4"
                minLength="4"
                outlined
                dense
              ></v-text-field>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :disabled="newmainExpenditureLoading"
            @click="dialog = false"
            >Bekor qilish</v-btn
          >
          <v-btn
            color="success"
            :disabled="newmainExpenditureLoading"
            @click="submit"
          >
            <i v-if="newmainExpenditureLoading" class="el-icon-loading"></i>
            Yuborish</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newmainExpenditureLoading: false,
      dialog: false,
      nameInput: '',
      codeInput: ''
    }
  },
  watch: {
    nameInput(val) {
      this.nameInput = val.charAt(0).toUpperCase() + val.slice(1)
    }
  },
  methods: {
    submit() {
      const data = {
        name: this.nameInput,
        code: this.codeInput
      }

      this.newmainExpenditureLoading = true
      this.$store
        .dispatch('createMainExpenditures', data)
        .then(() => {
          this.dialog = false
          this.nameInput = ''
          this.codeInput = ''
          this.newmainExpenditureLoading = false
        })
        .catch((err) => {
          console.error(err)
          this.newmainExpenditureLoading = false
        })
    }
  }
}
</script>

<style></style>
