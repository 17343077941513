<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <div class="navi-link" dark v-bind="attrs" v-on="on">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }} </span>
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('ACTION.UPDATE') }}</span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            label="Nomi"
            v-model="test.name"
          ></v-text-field>
          <v-text-field outlined label="Kod" v-model="test.code"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="dialog = false">Yopish</v-btn>
          <v-btn color="success" @click="submitUpdate">Saqlash</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      data2: {},
      data3: this.UpdateObj
    }
  },
  computed: {
    test() {
      const data3 = {
        name: this.UpdateObj.name,
        code: this.UpdateObj.code
      }
      return data3
    }
  },
  methods: {
    submitUpdate() {
      const id = {
        id: this.UpdateObj.id
      }
      const data = {
        name: this.test.name,
        code: this.test.code
      }
      this.$store.dispatch('updateMainExpendituires', { id, data })
      this.dialog = false
    }
  },
  props: {
    UpdateObj: Object
  }
}
</script>

<style scoped>
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem !important;
}
</style>
